import React, { Component } from 'react';
import styled from 'styled-components'

const designProjects = [
    {
    media: <video autoPlay={true} loop playsInline={true} controls={false} controlList='nodownload'><source src='https://res.cloudinary.com/dhsixzxzm/video/upload/v1576181233/Graphic%20Design/LOUISVUITTON-EN_w9d18n.mp4'></source></video>,
    title: "Louis Vuitton Women's Wear F/W 2019",
    caption: 'video, 1:17s, dimensions varied, 2019',
    credit: <div>SSENSE, 418 St Sulpice St, Montréal, QC</div>
    },
    {
        media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1578596142/Graphic%20Design/LV-01_nmivqh.jpg' alt=' '></img>,
        title: "Louis Vuitton Women's Wear F/W 2019",
        caption: 'installation view, dimensions varied, 2019',
        credit: <div>SSENSE, 418 St Sulpice St, Montréal, QC</div>
    },
    {
        media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1578596143/Graphic%20Design/LV-02_qqekg9.jpg' alt=' '></img>,
        title: "Louis Vuitton Women's Wear F/W 2019",
        caption: 'installation view, dimensions varied, 2019',
        credit: <div>SSENSE, 418 St Sulpice St, Montréal, QC</div>
    },
    {
        media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1578604713/Graphic%20Design/Salt-Adjusted_brwbtu.jpg' alt=' '></img>,
        title: 'Blessed, Salt',
        caption: 'album design, computer-generated image, 12 × 12", 2018',
        credit: <div></div>
    },
    {
        media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1578604713/Graphic%20Design/Salt-Insert-Adjusted_ukvqh3.jpg' alt=' '></img>,
        title: 'Blessed, Salt',
        caption: 'insert, computer-generated image, 12 × 12", 2018',
        credit: <div></div>
    },
    {
        media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1578604713/Graphic%20Design/Salt-Record-Adjusted_zzpgsa.jpg' alt=' '></img>,
        title: 'Blessed, Salt',
        caption: 'vinyl, 12 × 12", 2018',
        credit: <div></div>
    },
    {
        media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1578605596/Graphic%20Design/Jewel_syil5p.jpg' alt=' '></img>,
        title: 'Blessed, Salt',
        caption: 'CD, 13C, 2018',
        credit: <div></div>
    },
    // {
    //     media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1578347153/Graphic%20Design/Blessed_Salt_veuu7d.png' alt=' '></img>,
    //     title: 'Blessed, Salt',
    //     caption: 'live on audiotree, 2018',
    //     credit: <div></div>
    // },
    {
        media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1578344740/Art/2020-01-06-BlueClaw_eiidk2.jpg' alt=' '></img>,
        title: 'Nothing’s Lost Forever',
        caption: 'computer-generated image, 43 × 25", 2019',
        credit: <div>Commissioned for Mold Magazine Issue 4, Art Direction Matt Tsang and Eric Hu</div>
    },
    {
        media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1576175510/Art/07.-2019-12-04-Seesaw_sspi5h.jpg' alt=' '></img>,
        title: 'Seesaw',
        caption: 'computer-generated image, 43 × 25", 2019',
        credit: <div>Commissioned for Mold Magazine Issue 4, Art Direction Matt Tsang and Eric Hu</div>
    },
    // {
    //     media: <img src='https://res.cloudinary.com/wy/image/upload/f_auto,dpr_auto/v1560727333/nathanlevasseur/projects/07._EveryoneChanges.jpg' alt=' '></img>,
    //     title: 'Ader Error',
    //     caption: 'computer-generated image, 24 × 38", 2018',
    //     credit: <div></div>
    // },
    {
        media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1576175543/Art/AngelCum-Mid_om6n38.jpg' alt=' '></img>,
        title: 'Angel Cum',
        caption: 'typography, dimensions varied, 2019',
        credit: <div>Collaboration with James Oh</div>
    },
    {
        media: <video autoPlay={true} loop playsInline={true} controls={false} controlList='nodownload'><source src='https://res.cloudinary.com/dhsixzxzm/video/upload/v1578674344/Art/2020-01-10-Final_Video_Ceramic_h2rs9d.mp4'></source></video>,
        title: 'Family Portrait',
        caption: 'video, dimensions varied, 2019',
        credit: <div>Sound design Evan Allen James</div>
    },
    {
        media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1578595497/Graphic%20Design/DIS-03_dugdy5.jpg' alt=' '></img>,
        title: 'XOXO, Safety Net',
        caption: 'window display typography, dimensions varied, 2018',
        credit: <div>SSENSE, 418 St Sulpice St, Montréal, QC</div>
    },
    {
        media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1578595498/Graphic%20Design/DIS-01_oxxcfx.jpg' alt=' '></img>,
        title: 'XOXO, Safety Net',
        caption: 'window detail, dimensions varied, 2018',
        credit: <div>SSENSE, 418 St Sulpice St, Montréal, QC</div>
    },
    {
        media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1578595498/Graphic%20Design/DIS-04_e9vew4.jpg' alt=' '></img>,
        title: 'XOXO, Safety Net',
        caption: 'installation view, dimensions varied, 2018',
        credit: <div>SSENSE, 418 St Sulpice St, Montréal, QC</div>
    },
    {
        media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1578595498/Graphic%20Design/DIS-02_woq8ib.jpg' alt=' '></img>,
        title: 'XOXO, Safety Net',
        caption: 'installation view, dimensions varied, 2018',
        credit: <div>SSENSE, 418 St Sulpice St, Montréal, QC</div>
    },
    {
        media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1578588717/Graphic%20Design/Please_Touch_kgzwbz.jpg' alt=' '></img>,
        title: 'Please Touch, Gently',
        caption: 'book, limited run, 5 × 7", 2016',
        credit: <div>Author Jenna-Katheryn Heinemann</div>
    },
    {
        media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1576175535/Art/11._Control_Me_utdo1c.jpg' alt=' '></img>,
        title: 'Control Me',
        caption: 'computer-generated image, 10.3 × 6.8", 2017',
        credit: <div></div>
    },
    {
        media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1578602468/Art/Helmut-Lang_zhybcj.jpg' alt=' '></img>,
        title: 'Helmut Lang',
        caption: 't-shirt, dimensions varied , 2018',
        credit: <div>Unrealized concept</div>
    },
    {
        media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1578604713/Graphic%20Design/Red-Adjusted_x7wlbd.jpg' alt=' '></img>,
        title: 'Blessed, II',
        caption: 'album design, 12 × 12", 2017',
        credit: <div></div>
    },
    {
        media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1578604713/Graphic%20Design/Red-Vinyl-Adjusted_n8hkvg.jpg' alt=' '></img>,
        title: 'Blessed, II',
        caption: 'vinyl, 12 × 12", 2017',
        credit: <div></div>
    },
    {
        media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1578585899/Graphic%20Design/FairUse_main_moau32.gif' alt=' '></img>,
        title: 'Fair Use',
        caption: 'exhibition identity, dimensions varied, 2017',
        credit: <div>Collaboration with Skye-Oleson Cormack</div>
    },
    {
        media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1576175664/Art/Vulnerable_Productivity_fz6ixb.jpg' alt=' '></img>,
        title: 'Vulnerable Productivity',
        caption: 'installation, 144 × 96", 2016',
        credit: <div>Collaboration with Jacob Dutton</div>
    },
    {
        media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1576175664/Art/Vulnerable_Productivity_2_todnkz.jpg' alt=' '></img>,
        title: 'Vulnerable Productivity',
        caption: 'installation, 144 × 96", 2016',
        credit: <div>Collaboration with Jacob Dutton</div>
    },
    {
        media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1576175993/Graphic%20Design/22._Stepmothers_rjsqvh.jpg' alt=' '></img>,
        title: 'Stepmothers',
        caption: 'cassette, 4 × 2.5", 2015',
        credit: <div></div>
    },
    {
        media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1578604243/Art/Pleasure_rall1m.jpg' alt=' '></img>,
        title: 'Pleasure Objects',
        caption: 'computer-generated image on glossy photo paper, 15.25 × 21.5", 2019',
        credit: <div>Collaboration with Jacob Dutton , Rad Hourani private collection</div>
    },
    {
        media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1576175629/Art/ShopOfHorrors-Mid_pft4bs.jpg' alt=' '></img>,
        title: '恐怖之屋',
        caption: 'typography, dimensions varied, 2018',
        credit: <div>Commissioned for <a href='https://www.ssense.com/en-us/editorial/culture/ssense-shop-of-horrors' target="_blank" rel="noopener noreferrer">Shop of Horrors</a>, SSENSE</div>
    },
    {
        media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1578681202/Graphic%20Design/Ader-Error_ftravg.jpg' alt=' '></img>,
        title: 'Ader Error',
        caption: 'image treatment, 2018',
        credit: <div>Online campaign to support exclusive product launch</div>
    },
    {
        media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1578596942/Graphic%20Design/TailGatePoster22_m1f8ap.jpg' alt=' '></img>,
        title: 'Tailgate Gallery',
        caption: 'event poster, 11 × 17", 2017',
        credit: <div></div>
    },
    {
        media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1578596942/Graphic%20Design/TailGateGallery_fh5rvk.jpg' alt=' '></img>,
        title: 'Tailgate Gallery',
        caption: 'illustration, 2017',
        credit: <div></div>
    },
    {
        media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1576175551/Art/HappinessObjects-02_scqoij.jpg' alt=' '></img>,
        title: 'Happiness Objects',
        caption: 'installation, dimensions varied, 2017',
        credit: <div>Collaboration with TJ McLachlan</div>
    },
    {
        media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1576175551/Art/HappinessObjects-01_tksano.jpg' alt=' '></img>,
        title: 'Happiness Objects',
        caption: 'installation, dimensions varied, 2017',
        credit: <div>Collaboration with TJ McLachlan</div>
    },
    {
        media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1576176244/Art/2019-07-17-RalphLauren-RGB-Print_yxndeh.jpg' alt=' '></img>,
        title: 'Perfume',
        caption: 'computer-generated image, dimensions varied, 2019',
        credit: <div>Commissioned for <a href='http://ssense.com/en-us/editorial/fashion/the-ralph-lauren-ralphabet' target="_blank" rel="noopener noreferrer">The Ralph Lauren Ralphabet</a>, SSENSE</div>
    },
    {
        media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1578585874/Graphic%20Design/Drav_01_All_Cans_udbbq5.jpg' alt=' '></img>,
        title: 'Drav',
        caption: 'identity, label, 4.83 × 2.6", 2018',
        credit: <div>Art Direction Justin Lortie, Wedge</div>
    },
    {
        media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1578585879/Graphic%20Design/Drav_03_Single_Can_mtczch.jpg' alt=' '></img>,
        title: 'Drav',
        caption: 'identity, label, 4.83 × 2.6", 2018',
        credit: <div>Art Direction Justin Lortie, Wedge</div>
    },
]

export default class DesignProjects extends Component {

    constructor(props) {
        super(props)
        this.state = {
            currentProj: 0,
            password: true
        }
    }

    componentDidMount() {
        window.onkeydown = (e) => {
            let code = e.keyCode;
            let passwordEntered = document.getElementById('passWall')

            if (code === 13 && document.activeElement === passwordEntered) { // up down arrow
                this.submitPassword(e)
            } else return
        }
    }

    nextProj = () => {
        if (this.state.currentProj === designProjects.length - 1) {
            this.setState({ currentProj: 0 });
        } else
            this.setState({ currentProj: this.state.currentProj + 1 });
    }

    prevProj = () => {
        if (this.state.currentProj === 0) {
            this.setState({ currentProj: designProjects.length - 1 });
        } else
            this.setState({ currentProj: this.state.currentProj - 1 });
    }

    callBack = () => {
        return
    }

    //validates password field
    submitPassword = () => {
        let passwordEntered = document.getElementById('passWall').value;
        if (passwordEntered === 'Natasha200') {
            this.setState({ password: false })
        } else {
            document.getElementById('error').innerHTML = 'The password you entered is incorrect. <br/> Please try again.'
        }
    }

    render() {
        return (
            <div>
                {
                    this.state.password ?
                        // password wall
                        <PasswordWall>
                            <Error id='error'></Error>
                            <input type="password" id='passWall' />
                            <br />
                            <Submit id='submit-button' onClick={this.submitPassword}>Submit</Submit>
                        </PasswordWall>
                        :
                        // design projects
                        <ProjectWrapper>
                            <NavButtons>
                                <Left onClick={this.prevProj}></Left>
                                <Right onClick={this.nextProj}></Right>
                            </NavButtons>
                            <ProjImgWrapper>
                                <ProjectImg>
                                    <ImgWrapper>{designProjects[this.state.currentProj].media}</ImgWrapper>
                                    <ProjectInfo>
                                        <div>
                                            <Title>{designProjects[this.state.currentProj].title}</Title>
                                            <Info>, {designProjects[this.state.currentProj].caption}</Info>
                                        </div>
                                        <Credit>{designProjects[this.state.currentProj].credit}</Credit>
                                    </ProjectInfo>
                                </ProjectImg>
                            </ProjImgWrapper>
                        </ProjectWrapper>
                }
            </div>
        )
    }
}

const ProjectWrapper = styled.div`
    padding: 22.5px 0 ;
`
const ProjImgWrapper = styled.div`
    display: flex;
    justify-content: center;
`
const ImgWrapper = styled.div`
`
const ProjectImg = styled.div`
    flex-flow: row wrap;
    position: absolute;
    justify-content: center;
    text-align: center;
    display: table;
    flex: 1 1;

    img {
        max-width: 90vw;
        max-height: calc(65vh - 115px);
    }

    video {
        max-width: 90vw;
        max-height: calc(65vh - 115px);
        background-color: white;
    }

    @media (min-width: 768px) {
        img {
        max-width: 90vw;
        max-height: calc(85vh - 115px);
    }

    video {
        max-width: 90vw;
        max-height: calc(85vh - 115px);
        background-color: white;
    }
}
`
const NavButtons = styled.div`
    -webkit-tap-highlight-color:transparent;
`
const Left = styled.div`
    position: absolute;
    cursor: w-resize;
    left: 0;
    top: 0;
    height: calc(100% - 100px);
    width: calc(50vw);
    z-index: 500;
`
const Right = styled.div`
    position: absolute;
    cursor: e-resize;
    right: 0;
    top: 0;
    height: calc(100% - 100px);
    width: calc(50vw);
    z-index: 500;
`
const ProjectInfo = styled.div`
    font-family: sans-serif;
    font-weight: 600;
    font-size: calc(8px + 1vh);
    transform: scale(1, 1.1);
    text-align: center;
    margin-top: 15px;
    padding: 0 45px;
    cursor: default;
    z-index: 1000;
    position: relative;

    p {
        margin: 0;
    }

    a {
        text-decoration: none;
        color: black;
    }

    @media (min-width: 768px) {
        position: fixed;
        left: 0;
        right: 0;
        top: 78vh;
        box-sizing: border-box;
        clear: both;
    }
`
const Credit = styled.div`
`
const Title = styled.span`
    font-style: italic;
`
const Info = styled.span`
`
const PasswordWall = styled.div`
    text-align: center;
    font-family: sans-serif;
    font-weight: 600;
    font-size: calc(8px + 1vh);
    transform: scale(1, 1.1);
    position: absolute;
    left: 0;
    right: 0;
    top: 40vh;

    *:focus {
        outline: none;
    }

    input {
        height: 25px;
        width: 190px;
        font-family: sans-serif;
        font-weight: 600;
        font-size: calc(16px + 1vh);
        transform: scale(1, 1.1);
        padding: 0 5px;
    }

    @media (min-width: 768px) {
        top: 45vh;

        input {
            font-size: calc(8px + 1vh);
        }
    }
`
const Submit = styled.div`
    margin-top: 15px;
    cursor: pointer;
`
const Error = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 80px;
`