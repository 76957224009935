import React, { Component } from 'react';
import ArtProjects from './art'

export default class Home extends Component {
    render() {
        return (
            <div>
                <ArtProjects />
            </div>
        )
    }
}