import React, { Component } from 'react';
import './App.css';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from './components/home'
import DesignProjects from './components/design'
import Footer from './components/footer'

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div>
          <Switch>
            <Route exact path="/" >
              <Home />
            </Route>
            <Route path="/design">
              <DesignProjects />
            </Route>
          </Switch>
          <Footer />
        </div>
      </BrowserRouter>

    )
  };
}

export default App;
