import React, { Component } from 'react';
import styled from 'styled-components'

const projects = [
{
    media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1576174426/Art/01._ToldMyself_Edit_sagf9p.jpg' alt=' '></img>,
    title: 'Told Myself',
    caption: 'computer-generated image on glossy photo paper, 34 × 45", 2019',
    credit: <div></div>
},
{
    media: <video autoPlay={true} loop playsInline={true} controls={false} controlList='nodownload'><source src='https://res.cloudinary.com/dhsixzxzm/video/upload/v1578674344/Art/2020-01-10-Final_Video_Ceramic_h2rs9d.mp4'></source></video>,
    title: 'Family Portrait',
    caption: 'video, 0:30s, dimensions varied, 2019',
    credit: <div>Sound design Evan Allen James</div>
},
// {
//     media: <video autoPlay={true} loop playsInline={true} controls={false} controlList='nodownload'><source src='https://res.cloudinary.com/dhsixzxzm/video/upload/v1576174177/Art/00_Final_Video_Ceramic_n0st6n.mp4'></source></video>,
//     title: 'Family Portrait',
//     caption: <span>video <a href='http://ssense.com'>image</a> on glossy photo paper, 34 × 45", 2019</span>,
//     credit: <div></div>
// },
{
    media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1578601501/Art/Podium_sybyrm.jpg' alt=' '></img>,
    title: "Look Who's Back in the Wrong Place at the Right Time",
    caption: 'computer-generated image, 55 × 24", 2019',
    credit: <div></div>
},
{
    media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1576174436/Art/02._Bottles_wuwtmx.jpg' alt=' '></img>,
    title: 'Bottles',
    caption: 'computer-generated image, 45 × 34", 2018',
    credit: <div></div>
},
{
    media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1576175641/Art/Single-03-NoType-HR_u6wj1x.jpg' alt=' '></img>,
    title: 'Bottles',
    caption: 'computer-generated image, 34 × 34", 2018',
    credit: <div></div>
},
{
    media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1576174444/Art/03._LoveHowISee_xhiupy.jpg' alt=' '></img>,
    title: 'Love How I See',
    caption: 'print, 20 × 30", 2016',
    credit: <div>Collaboration with Jacob Dutton</div>
},
{
    media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1578344740/Art/2020-01-06-BlueClaw_eiidk2.jpg' alt=' '></img>,
    title: 'Nothing’s Lost Forever',
    caption: 'computer-generated image, 43 × 25", 2019',
    credit: <div>Commissioned for Mold Magazine Issue 4, Art Direction Matt Tsang and Eric Hu</div>
},
{
    media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1576175510/Art/07.-2019-12-04-Seesaw_sspi5h.jpg' alt=' '></img>,
    title: 'Seesaw',
    caption: 'computer-generated image, 43 × 25", 2019',
    credit: <div>Commissioned for Mold Magazine Issue 4, Art Direction Matt Tsang and Eric Hu</div>
},
{
    media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1576175577/Art/08._Move_On_vad4zr.jpg' alt=' '></img>,
    title: 'Move On',
    caption: 'sculpture, dimensions varied, 2016',
    credit: <div></div>
},
{
    media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1576175570/Art/09._EveryoneChanges_kkkkkv.jpg' alt=' '></img>,
    title: 'Everyone Changes',
    caption: 'computer-generated image, 24 × 38", 2018',
    credit: <div></div>
},
{
    media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1578601881/Art/Masks_nxwiyf.jpg' alt=' '></img>,
    title: 'Masks',
    caption: 'computer-generated image, dimensions varied, 2018',
    credit: <div></div>
},
{
    media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1576175535/Art/11._Control_Me_utdo1c.jpg' alt=' '></img>,
    title: 'Control Me',
    caption: 'computer-generated image, 10.3 × 6.8", 2018',
    credit: <div></div>
},
// {
//     media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1576175587/Art/12._Haichuu_zy49al.jpg' alt=' '></img>,
//     title: 'ハイチュウ(Haichuu)',
//     caption: 'poster, 6 × 8.25" , 2017',
//     credit: <div></div>
// },
{
    media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1576175543/Art/AngelCum-Mid_om6n38.jpg' alt=' '></img>,
    title: 'Angel Cum',
    caption: 'typography, dimensions varied, 2019',
    credit: <div>Collaboration with James Oh</div>
},
{
    media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1576175986/Graphic%20Design/12._Red_Flower_t4rpos.jpg' alt=' '></img>,
    title: 'Red Flower',
    caption: 'computer-generated image, 24 × 24", 2017',
    credit: <div></div>
},
{
    media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1576175849/Art/Loss-Series_5x7.5_wqoadk.jpg' alt=' '></img>,
    title: 'Loss Series',
    caption: 'computer-generated image on glossy paper, 5 × 7", 2018',
    credit: <div></div>
},
{
    media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1576175856/Art/DesperateEnough_f9riqc.jpg' alt=' '></img>,
    title: 'Desperate Enough',
    caption: 'computer-generated image on glossy paper, 5 × 7", 2018',
    credit: <div></div>
},
// {
//     media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1576176003/Graphic%20Design/16._Salt_yegzuu.jpg' alt=' '></img>,
//     title: 'Salt',
//     caption: 'CD, 13C, 2019',
//     credit: <div></div>
// },
// {
//     media: <img src='https://res.cloudinary.com/wy/image/upload/f_auto,dpr_auto/v1560727355/nathanlevasseur/projects/18._Cleaning_Supplies.jpg' alt=' '></img>,
//     title: 'Cleaning Supplies',
//     caption: 'computer-generated image, 36 × 36", 2018',
//     credit: <div></div>
// },
// {
//     media: <img src='https://res.cloudinary.com/wy/image/upload/f_auto,dpr_auto/v1560727347/nathanlevasseur/projects/19._Cleaning_Supplies.jpg' alt=' '></img>,
//     title: 'Cleaning Supplies',
//     caption: 'computer-generated image, 36 × 36", 2018',
//     credit: <div></div>
// },
{
    media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1576175664/Art/Vulnerable_Productivity_fz6ixb.jpg' alt=' '></img>,
    title: 'Vulnerable Productivity',
    caption: 'installation, 144 × 96", 2016',
    credit: <div>Collaboration with Jacob Dutton</div>
},
{
    media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1576175664/Art/Vulnerable_Productivity_2_todnkz.jpg' alt=' '></img>,
    title: 'Vulnerable Productivity',
    caption: 'installation, 144 × 96", 2016',
    credit: <div>Collaboration with Jacob Dutton</div>
},
// {
//     media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1576175993/Graphic%20Design/22._Stepmothers_rjsqvh.jpg' alt=' '></img>,
//     title: 'Stepmothers',
//     caption: 'cassette, 4 × 2.5", 2019',
//     credit: <div></div>
// },
{
    media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1578604243/Art/Pleasure_rall1m.jpg' alt=' '></img>,
    title: 'Pleasure Objects',
    caption: 'computer-generated image on glossy photo paper, 15.25 × 21.5", 2019',
    credit: <div>Collaboration with Jacob Dutton , Rad Hourani private collection</div>
},
{
    media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1576175927/Art/25._Film_Still_01_sylxqr.jpg' alt=' '></img>,
    title: 'Film Still #02',
    caption: 'computer-generated image on screen, 28 × 48", 2018',
    credit: <div></div>
},
{
    media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1576175551/Art/HappinessObjects-02_scqoij.jpg' alt=' '></img>,
    title: 'Happiness Objects',
    caption: 'installation, dimensions varied, 2017',
    credit: <div>Collaboration with TJ McLachlan</div>
},
{
    media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1576175551/Art/HappinessObjects-01_tksano.jpg' alt=' '></img>,
    title: 'Happiness Objects',
    caption: 'installation, dimensions varied, 2017',
    credit: <div>Collaboration with TJ McLachlan</div>
},
// {
//     media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1576175849/Art/LaundryAloneBackground_tgjkn7.jpg' alt=' '></img>,
//     title: 'Didn’t Feel Alone',
//     caption: 'computer-generated image, 8.25 × 10.75", 2018',
//     credit: <div></div>
// },
// {
//     media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1576175629/Art/ShopOfHorrors-Mid_pft4bs.jpg' alt=' '></img>,
//     title: '恐怖之屋',
//     caption: 'typography, dimensions varied, 2018',
//     credit: <div></div>
// },
// {
//     media: <img src='https://res.cloudinary.com/wy/image/upload/f_auto,dpr_auto/v1560727339/nathanlevasseur/projects/30_Without.jpg' alt=' '></img>,
//     title: 'Without',
//     caption: 'computer-generated image, 45 × 34", 2018',
//     credit: <div></div>
// },
{
    media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1576175603/Art/IsntItSad-01_qsopsr.jpg' alt=' '></img>,
    title: 'Isn’t It',
    caption: 'sculpture, dimensions varied, 2017',
    credit: <div></div>
},
{
    media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1576175849/Art/LackOf_pl852a.jpg' alt=' '></img>,
    title: 'Lack Of',
    caption: 'sculpture, dimensions varied, 2016',
    credit: <div></div>
},
// {
//     media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1576175825/Art/Vaste-test_s7qg2h.jpg' alt=' '></img>,
//     title: 'Mended Vase',
//     caption: 'ceramic, 10.5 × 8 × 6", 2018',
//     credit: <div></div>
// },
{
    media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1576176244/Art/2019-07-17-RalphLauren-RGB-Print_yxndeh.jpg' alt=' '></img>,
    title: 'Perfume',
    caption: 'computer-generated image, dimensions varied, 2018',
    credit: <div></div>
},
// {
//     media: <img src='https://res.cloudinary.com/wy/image/upload/f_auto,dpr_auto/v1560727353/nathanlevasseur/projects/35._B_Side.jpg' alt=' '></img>,
//     title: 'Transparent B Side',
//     caption: 'vinyl, 36 × 36", 2017',
//     credit: <div></div>
// },
{
    media: <img src='https://res.cloudinary.com/dhsixzxzm/image/upload/v1576175650/Art/SoftenLikeBoiled_kh3hpg.jpg' alt=' '></img>,
    title: 'You Soften Like Boiled Rocks',
    caption: 'computer-generated image on glossy photo paper, 34 × 45", 2019',
    credit: <div></div>
},
]

export default class ArtProjects extends Component {

    constructor(props) {
        super(props)
        this.state = {
            currentProj: 0
        }
    }

    nextProj = () => {
        if (this.state.currentProj === projects.length - 1) {
            this.setState({ currentProj: 0 });
        } else
            this.setState({ currentProj: this.state.currentProj + 1 });
    }

    prevProj = () => {
        if (this.state.currentProj === 0) {
            this.setState({ currentProj: projects.length - 1 });
        } else
            this.setState({ currentProj: this.state.currentProj - 1 });
    }

    callBack = () => {
        return
    }

    render() {
        return (
            <ProjectWrapper>
                <NavButtons>
                    <Left onClick={this.prevProj}></Left>
                    <Right onClick={this.nextProj}></Right>
                </NavButtons>
                <ProjImgWrapper>
                    <ProjectImg>
                        <ImgWrapper>{projects[this.state.currentProj].media}</ImgWrapper>
                        <ProjectInfo>
                            <div>
                                <Title>{projects[this.state.currentProj].title}</Title>
                                <Info>, {projects[this.state.currentProj].caption}</Info>
                            </div>
                            <Credit>{projects[this.state.currentProj].credit}</Credit>
                        </ProjectInfo>
                    </ProjectImg>
                </ProjImgWrapper>
            </ProjectWrapper>
        )
    }
}

const ProjectWrapper = styled.div`
    padding: 22.5px 0 ;
`
const ProjImgWrapper = styled.div`
    display: flex;
    justify-content: center;
`
const ImgWrapper = styled.div`
`
const ProjectImg = styled.div`
    flex-flow: row wrap;
    position: absolute;
    justify-content: center;
    text-align: center;
    display: table;
    flex: 1 1;

    img {
        max-width: 90vw;
        max-height: calc(65vh - 115px);
    }

    video {
        max-width: 90vw;
        max-height: calc(65vh - 115px);
        background-color: white;
    }

    @media (min-width: 768px) {
        img {
        max-width: 90vw;
        max-height: calc(85vh - 115px);
    }

    video {
        max-width: 90vw;
        max-height: calc(85vh - 115px);
        background-color: white;
    }
}
`
const NavButtons = styled.div`
    -webkit-tap-highlight-color:transparent;
`
const Left = styled.div`
    position: absolute;
    cursor: w-resize;
    left: 0;
    top: 0;
    height: calc(100% - 100px);
    width: calc(50vw);
    z-index: 500;
`
const Right = styled.div`
    position: absolute;
    cursor: e-resize;
    right: 0;
    top: 0;
    height: calc(100% - 100px);
    width: calc(50vw);
    z-index: 500;
`
const ProjectInfo = styled.div`
    font-family: sans-serif;
    font-weight: 600;
    font-size: calc(8px + 1vh);
    transform: scale(1, 1.1);
    text-align: center;
    margin-top: 15px;
    padding: 0 45px;
    cursor: default;
    z-index: 1000;
    position: relative;

    p {
        margin: 0;
    }

    a {
        text-decoration: none;
        color: black;
    }

    @media (min-width: 768px) {
        position: fixed;
        left: 0;
        right: 0;
        top: 78vh;
        box-sizing: border-box;
        clear: both;
    }
`
const Credit = styled.div`
`
const Title = styled.span`
      font-style: italic;
`
const Info = styled.span`
`