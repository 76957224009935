import React, { Component } from 'react';
import styled from 'styled-components'

export default class Footer extends Component {
    render() {
        return (
            <ConactInfo>
                <Bio>Nathan Levasseur is an artist with a focused interest in mixed-reality and commodification.</Bio>
                <Contact><a href="mailto:hello@nathanlevasseur.com">Contact</a></Contact>
            </ConactInfo>
        )
    }
}

const ConactInfo = styled.div`
    font-family: sans-serif;
    font-weight: 600;
    font-size: calc(8px + 1vh);
    transform: scale(1, 1.1);
    margin-top: 15px;
    display: block;
    position: fixed;
    bottom: 0; 
    padding: 22.5px 0;

    a {
        text-decoration: none;
        color: black;
    }

    @media (min-width: 768px) {  
        display: flex;
    }
`
const Bio = styled.div`
    padding: 0 22.5px 22.5px 22.5px;

    @media (min-width: 768px) {  
        width: 33vw;
        padding: 0 45px;
    }
`
const Contact = styled.div`
        padding: 0 22.5px;

        @media (min-width: 768px) {  
            padding: 0 45px;
    }
`